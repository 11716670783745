var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.error,"successMessage":_vm.message,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    loading: _vm.loadingSave,
    disabled:
      _vm.loading ||
      Object.keys(this.updatedFields).length === 0 ||
      _vm.disablePrimaryButton
  },"topActionButtonSecondary":{
    text: 'Actions',
    key: 'actions',
    subItems: [
      {
        title: 'View Tutorial',
        command: 'view-tutorial',
        disabled: _vm.loading
      },
      {
        title: 'Update Password',
        command: 'update-password',
        disabled: _vm.loading
      },
      {
        title: 'Cancel',
        command: 'cancel'
      }
    ]
  },"showTopActionButtonPrimaryAtBottom":false},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.error = ''},"successMessageClosed":function($event){_vm.message = ''}}},[_c('custom-alert',{attrs:{"title":"Notice!","message":_vm.licenseErrorMessage,"show":!!_vm.licenseErrorMessage,"type":"danger"},on:{"dismiss":function($event){_vm.licenseErrorMessage = ''}}}),(_vm.initialized)?_c('div',[(_vm.isAdmin)?_c('UserEditFields',{ref:"formBuilderUserForm",attrs:{"addNewUserFields":_vm.profileFields},on:{"validationChanged":_vm.validationChangeHandler}}):[_c('ReadOnlyProfileInfo',{attrs:{"isAdjuster":_vm.isAdjuster,"isAgent":_vm.isAgent,"userInfo":_vm.getEditingUser || {}}}),_c('UserCompanies',{attrs:{"companyIds":_vm.getEditingUser && _vm.getEditingUser.companyIds
            ? _vm.getEditingUser.companyIds
            : []}})],(_vm.isAgent || _vm.isAgencyAdmin || _vm.isSubAdmin)?_c('AgentLicenseUpdateFields',{ref:"formBuilderLicenseForm",attrs:{"editingValues":_vm.getEditingUser ? _vm.getEditingUser : {}},on:{"validationChanged":_vm.validationChangeHandler}}):_vm._e(),(_vm.isAgent || _vm.isAdjuster)?_c('div',{staticClass:"pt-8"},[_vm._v(" "+_vm._s(_vm.profileChangeRequest.text)+" "),_c('a',{staticClass:"fw-bold text-atlas-blue",attrs:{"href":_vm.profileChangeRequest.link}},[_vm._v("Click here")])]):_vm._e(),_c('ModalBase',{attrs:{"name":"selectChangePassword","size":"medium","clickToClose":false,"showClose":true,"bottomButtonPrimary":{
        key: 'save',
        disabled: _vm.disabled,
        label: 'Save'
      },"bottomButtonSecondary":{
        key: 'cancel',
        label: 'Cancel'
      }},on:{"primaryButtonClick":_vm.changePassword}},[_c('template',{slot:"title"},[_vm._v("Change Password")]),_c('Password',{ref:"passwordComponent",staticClass:"w-full",attrs:{"isAdmin":_vm.isCurrentUserAdmin,"loading":false,"isNew":false,"passwordMessage":_vm.passwordMessage},on:{"disable":function($event){return _vm.setDisabled($event)}}})],2),_c('saveChanges',{on:{"clickedSave":_vm.beforeRouteLeaveSave,"clickedDiscard":_vm.beforeRouteLeaveDiscardChanges}})],2):_vm._e(),_c('Help',{attrs:{"title":`${_vm.isAdjuster ? 'Claims' : 'Marketing'} Inquiries`,"messages":_vm.messages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export const readOnlyProfileData = (user: any) => {
  return [
    {
      sectionTitle: "Personal Details",
      showSection: true,
      showDivider: false,
      sectionColumnSize: "6",
      information: [
        {
          informationColumnSize: "2",
          key: "First Name",
          value: user?.firstName,
          showColumn: !!user?.firstName
        },
        {
          informationColumnSize: "2",
          key: "Last Name",
          value: user?.lastName,
          showColumn: !!user?.lastName
        },
        {
          informationColumnSize: "2",
          key: "Display Name",
          value: user?.displayName,
          showColumn: !!user?.displayName
        }
      ]
    },
    {
      sectionTitle: "",
      showSection: true,
      showDivider: false,
      sectionColumnSize: "6",
      information: [
        {
          informationColumnSize: "2",
          key: "Email Address",
          value: user?.email,
          showColumn: !!user?.email
        },
        {
          informationColumnSize: "2",
          key: "Primary Phone Number",
          value: user?.phone.primary,
          showColumn: !!user?.phone.primary
        },
        {
          informationColumnSize: "2",
          key: "Secondary Phone Number",
          value: user?.phone.secondary,
          showColumn: !!user?.phone.secondary
        }
      ]
    },
    {
      sectionTitle: "Physical Address",
      showSection: !!user?.physicalAddress._id,
      showDivider: true,
      sectionColumnSize: "6",
      information: [
        {
          informationColumnSize: "2",
          key: "House Number",
          value: user?.physicalAddress?.houseNumber,
          showColumn: !!user?.physicalAddress?.houseNumber
        },
        {
          informationColumnSize: "2",
          key: "Stret Direction",
          value: user?.physicalAddress?.streetDirection,
          showColumn: !!user?.physicalAddress?.streetDirection
        },
        {
          informationColumnSize: "2",
          key: "Unit Number",
          value: user?.physicalAddress?.unitNumber,
          showColumn: !!user?.physicalAddress?.unitNumber
        }
      ]
    },
    {
      sectionTitle: "",
      showSection: !!user?.physicalAddress._id,
      showDivider: false,
      sectionColumnSize: "4",
      information: [
        {
          informationColumnSize: "2",
          key: "Street Name",
          value: user?.physicalAddress?.streetName,
          showColumn: !!user?.physicalAddress?.streetName
        },
        {
          informationColumnSize: "2",
          key: "Street Type",
          value: user?.physicalAddress?.streetType,
          showColumn: !!user?.physicalAddress?.streetType
        }
      ]
    },
    {
      sectionTitle: "",
      showSection: !!user?.physicalAddress._id,
      showDivider: false,
      sectionColumnSize: "6",
      information: [
        {
          informationColumnSize: "2",
          key: "City",
          value: user?.physicalAddress?.city,
          showColumn: !!user?.physicalAddress?.city
        },
        {
          informationColumnSize: "2",
          key: "State",
          value: user?.physicalAddress?.state,
          showColumn: !!user?.physicalAddress?.state
        },
        {
          informationColumnSize: "2",
          key: "Zip Code",
          value: user?.physicalAddress?.zipCode,
          showColumn: !!user?.physicalAddress?.zipCode
        }
      ]
    },
    {
      sectionTitle: "Mailing Address",
      showSection: !!user?.mailingAddress._id,
      showDivider: true,
      sectionColumnSize: "6",
      information: [
        {
          informationColumnSize: "2",
          key: "House Number",
          value: user?.mailingAddress?.houseNumber,
          showColumn: !!user?.mailingAddress?.houseNumber
        },
        {
          informationColumnSize: "2",
          key: "Stret Direction",
          value: user?.mailingAddress?.streetDirection,
          showColumn: !!user?.mailingAddress?.streetDirection
        },
        {
          informationColumnSize: "2",
          key: "Unit Number",
          value: user?.mailingAddress?.unitNumber,
          showColumn: !!user?.mailingAddress?.unitNumber
        }
      ]
    },
    {
      sectionTitle: "",
      showSection: !!user?.mailingAddress._id,
      showDivider: false,
      sectionColumnSize: "4",
      information: [
        {
          informationColumnSize: "2",
          key: "Street Name",
          value: user?.mailingAddress?.streetName,
          showColumn: !!user?.mailingAddress?.streetName
        },
        {
          informationColumnSize: "2",
          key: "Street Type",
          value: user?.mailingAddress?.streetType,
          showColumn: !!user?.mailingAddress?.streetType
        }
      ]
    },
    {
      sectionTitle: "",
      showSection: !!user?.mailingAddress._id,
      showDivider: false,
      sectionColumnSize: "6",
      information: [
        {
          informationColumnSize: "2",
          key: "City",
          value: user?.mailingAddress?.city,
          showColumn: !!user?.mailingAddress?.city
        },
        {
          informationColumnSize: "2",
          key: "State",
          value: user?.mailingAddress?.state,
          showColumn: !!user?.mailingAddress?.state
        },
        {
          informationColumnSize: "2",
          key: "Zip Code",
          value: user?.mailingAddress?.zipCode,
          showColumn: !!user?.mailingAddress?.zipCode
        }
      ]
    }
  ];
};

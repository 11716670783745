
import Vue from "vue";
import { doFormatDateMMDDYYYY } from "@/store/modules/propertychangerequest/utils";
export default Vue.extend({
  name: "ReadOnlyProfileInfo",
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isAdjuster: {
      type: Boolean,
      required: true,
      default: false
    },
    isAgent: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    formatDate(data: any) {
      return doFormatDateMMDDYYYY(data);
    }
  },
  computed: {
    userStatus(): string {
      if (this.userInfo) {
        if (this.userInfo.status === "completedTutorial") {
          return "Completed Training";
        }
        if (this.userInfo.status === "pendingTutorial") {
          return "Training";
        } else {
          return this.userInfo.status;
        }
      } else {
        return "";
      }
    }
  }
});

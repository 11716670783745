
import Vue from "vue";
import {
  userMapActions,
  userMapMutations,
  userMapGetters
} from "@/store/modules/user";
import { authMapGetters, authMapActions } from "@/store/modules/auth";
import UserEditFields from "@/components/User/UserEditFields.vue";
import Password from "@/components/User/Password.vue";
import { objectDifference, validateLicenseDateIsNotInPast } from "@/helpers";
import { profile } from "@/forms/shared/profile";
import { FormBlock } from "@/components/FormBuilder/types";
import Help from "@/components/Help/Inquiries.vue";
import ReadOnlyProfileInfo from "@/components/User/ReadOnlyProfileInfo.vue";
import UserCompanies from "@/components/User/UserCompanies.vue";
import { readOnlyProfileData } from "@/forms/shared/readOnlyProfile";
import {
  marketingContacts,
  marketingInquiries,
  claimsInquiries,
  claimsContacts
} from "@/helpers/selectOptions";
import AgentLicenseUpdateFields from "@/components/User/AgentLicenseUpdateFields.vue";
import { agencyMapActions, agencyMapGetters } from "@/store/modules/agency";
import { get } from "lodash";

export default Vue.extend({
  name: "UserProfile",
  components: {
    UserEditFields,
    Password,
    ReadOnlyProfileInfo,
    UserCompanies,
    Help,
    AgentLicenseUpdateFields,
    saveChanges: () => import("@/components/Dialog/saveChanges.vue"),
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  data(): any {
    return {
      activeIndex: "info",
      error: "",
      message: "",
      loading: false,
      showDiscardModal: false,
      loadingSave: false,
      showPassword: false,
      disabled: false,
      passwordMessage:
        "Type in your new password in the input fields on the left to update your password.",
      nextRoute: "",
      initialized: false,
      isAdjuster: false,
      isAdmin: false,
      isAgent: false,
      isAgencyAdmin: false,
      isUnderwriter: false,
      disablePrimaryButton: true,
      agencyAdminEmail: "",
      licenseErrorMessage: ""
    };
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    const hasProfileUpdates = Object.keys(this.updatedFields).length > 0;
    if (hasProfileUpdates) {
      this.nextRoute = next;
      this.$modal.show("discard-changes-modal");
    } else {
      return next();
    }
  },
  created: function() {
    this.getUserDetails().then(() => {
      this.SET_EDIT_USER(this.getCurrentUserId);
      this.isAdmin = this.getEditingUser?.role == "admin";
      this.isAdjuster = this.getEditingUser?.role == "adjuster";
      this.isAgent = this.getEditingUser?.role == "agent";
      this.isAgencyAdmin = this.getEditingUser?.role == "agencyAdmin";
      this.isSubAdmin = this.getEditingUser?.role == "subAdmin";
      this.isUnderwriter = this.getEditingUser?.role == "underwriting";
      this.initialized = true;
      if (this.isAgent) {
        this.fetchAgencyData(this.getEditingUser?.agencyId);
      }
    });
  },
  destroyed() {
    this.SET_EDIT_USER("");
  },
  computed: {
    ...authMapGetters(["getCurrentUserId", "isCurrentUserAdmin"]),
    ...userMapGetters(["getEditingUser", "getUserById"]),
    ...agencyMapGetters(["getAgencyById"]),
    updatedFields(): any {
      const edited = this.getEditingUser;
      const original = this.getUserById(this.getCurrentUserId);
      const allowedEmpty = [
        "phone.secondary",
        "phone.secondaryVerified.resendCodeDate",
        "physicalAddress.unitNumber",
        "mailingAddress.unitNumber",
        "mailingAddress.streetType",
        "physicalAddress.streetType",
        "mailingAddress.streetDirection",
        "physicalAddress.streetDirection"
      ];
      return edited && original
        ? objectDifference(edited, original, allowedEmpty)
        : {};
    },
    profileFields(): FormBlock[] {
      return profile(this.isAgent);
    },
    getData() {
      let data = {};
      data = {
        sections: readOnlyProfileData(this.getEditingUser)
      };
      return data;
    },
    profileChangeRequest() {
      if (this.isAdjuster) {
        return {
          text:
            "Changes to the Adjuster Profile must be made in writing to the Claims Department.",
          link:
            "mailto:ysabelj@atlasgeneral.com?subject=Request for Adjuster Profile Update"
        };
      } else if (this.isAgent) {
        const agency = this.getAgencyById(this.getEditingUser?.agencyId);
        let agencyAdminEmail = "";
        if (
          agency &&
          agency?.agencyAdminData &&
          agency?.agencyAdminData.email
        ) {
          agencyAdminEmail = agency.agencyAdminData.email;
        } else if (
          agency &&
          agency?.subAdminData &&
          agency?.subAdminData?.email
        ) {
          agencyAdminEmail = agency.subAdminData.email;
        }
        return {
          text:
            "Changes to your Agent Profile must be made in writing your Agency Admin.",
          link: `mailto:${agencyAdminEmail}?subject=Request for Agent Profile Update`
        };
      }
      return {};
    },
    isOnboarding(): boolean {
      return (
        this.getEditingUser?.status === "pendingTutorial" ||
        this.getEditingUser?.status === "completedTutorial"
      );
    },
    messages() {
      if (this.isAdjuster) {
        return [
          {
            title: "Claims Inquiries",
            contacts: claimsInquiries
          },
          {
            title: "Claims Contacts",
            contacts: claimsContacts
          }
        ];
      }
      return [
        {
          title: "Marketing Inquiries",
          contacts: marketingInquiries
        },
        {
          title: "Marketing Contacts",
          contacts: marketingContacts
        }
      ];
    }
  },
  methods: {
    ...userMapActions(["getUser", "updateUser"]),
    ...userMapMutations(["SET_EDIT_USER"]),
    ...authMapActions(["updatePassword"]),
    ...agencyMapActions(["getAgency"]),

    async getUserDetails(): Promise<any> {
      try {
        this.loading = true;
        await this.getUser(this.getCurrentUserId);
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    doCancel() {
      this.showDiscardModal = false;
      this.next();
    },
    async update(): Promise<void> {
      this.loading = true;
      this.loadingSave = true;
      const onboardingStatus = {
        isOnboarding: this.isOnboarding
      };
      try {
        const fieldsToUpdate = { ...this.updatedFields, ...onboardingStatus };
        await this.updateUser({
          id: this.getCurrentUserId,
          update: fieldsToUpdate
        });
        this.SET_EDIT_USER(this.getCurrentUserId);
        this.message = "User updated successfully";
        if (this.isOnboarding) {
          this.$router.push(`/onboarding`).catch(() => {});
        }
        if (this.$getCurrentUser.role === "admin") {
          this.$router.push(`/admin/dashboard`).catch(() => {});
        } else {
          this.$router.push(`/dashboard`).catch(() => {});
        }
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.loadingSave = false;
      }
    },
    async beforeRouteLeaveSave() {
      try {
        this.$modal.hide("discard-changes-modal");
        await this.update();
        this.nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.nextRoute(false);
      }
    },
    async beforeRouteLeaveDiscardChanges() {
      try {
        this.$modal.hide("discard-changes-modal");
        this.nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.nextRoute(false);
      }
    },
    async changePassword(): Promise<void> {
      const updatedPassword = await (this as any).$refs.passwordComponent.save();
      const data = {
        password: updatedPassword,
        isOnboarding: this.isOnboarding
      };
      this.loading = true;
      try {
        await this.updateUser({
          id: this.getCurrentUserId,
          update: data
        });
        this.SET_EDIT_USER(this.getCurrentUserId);
        this.message = "Password updated successfully";
        this.$modal.hide("selectChangePassword");
        this.$router.push("/").catch(() => {});
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    setDisabled(disable: boolean) {
      this.disabled = !!disable;
    },
    onToolbarItemClick(action: string): void {
      switch (action) {
        case "view-tutorial":
          this.$router.push("/onboarding").catch(() => {});
          break;
        case "save":
          this.update();
          break;
        case "cancel":
          this.$router.push("/").catch(() => {});
          break;
        case "update-password":
          this.$modal.show("selectChangePassword");

          break;
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    },
    async fetchAgencyData(agencyId: string): Promise<any> {
      try {
        await this.getAgency(agencyId);
      } catch (error) {
        this.errorMessage = "Error fetching agency data. Try again later.";
        this.$bugSnagClient.notify(error);
      }
    }
  },
  watch: {
    ["getEditingUser.licenseNumberExpirationDate"]: {
      handler(value: any) {
        //if license date is in past  alert user error
        const licenseNumberExpirationDate: any = value;
        const role = get(this.getEditingUser as any, "role");
        if (
          ["agencyAdmin", "agent", "subAdmin"].includes(role) &&
          licenseNumberExpirationDate &&
          !validateLicenseDateIsNotInPast(licenseNumberExpirationDate)
        ) {
          this.licenseErrorMessage = "Agent License number is expired";
        } else {
          this.licenseErrorMessage = "";
        }
      },
      immediate: true
    }
  }
});


import { license } from "@/forms/shared/license";
import { userMapMutations } from "@/store/modules/user";
import { UserFields } from "@/store/modules/user/types";
import Vue from "vue";
import FormBuilder from "../FormBuilder/FormBuilder.vue";
import { FormBlock } from "../FormBuilder/types";
export default Vue.extend({
  name: "AgentLicenseUpdateFields",
  components: { FormBuilder },
  props: {
    editingValues: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...userMapMutations({
      editField: "SET_USER_EDIT_FIELD"
    }),
    handleFormFieldChanged({
      key,
      value
    }: {
      key: UserFields;
      value: any;
    }): void {
      this.editField({ key, value });
    },
    handleValidationChanged($event: any): void {
      this.$emit("validationChanged", $event);
    }
  },
  computed: {
    isAgencyAdmin(): boolean {
      return (
        (this.editingValues &&
          this.editingValues.role &&
          (this.editingValues.role === "agencyAdmin" ||
            this.editingValues.role === "subAdmin")) ||
        false
      );
    },
    licenseFields(): FormBlock[] {
      return license(true);
    }
  }
});


import Vue from "vue";
import { getUserCompanies } from "@/helpers/companies";
export default Vue.extend({
  name: "UserCompanies",
  props: {
    companyIds: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  computed: {
    userCompanies() {
      return getUserCompanies("profile");
    }
  }
});
